import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

// Get the list of submenus for the current page
export function getTabMenu(
  elementList: BlockTypes['menu-nav']['data']['elementList'],
  path: string,
  activeMenuId?: string,
): BlockTypes['menu-nav']['data']['elementList'][number] {
  let tabMenu = null
  if (!elementList) return { elementList: null }

  for (let i = 0; i < elementList?.length; i++) {
    if (elementList?.[i].id) {
      // If the activeMenuId is provided, we return the list of submenus for this activeMenuId
      if (elementList?.[i].id === activeMenuId) {
        tabMenu = elementList[i]
        break
      }
    }

    const subMenus = elementList?.[i]?.elementList || []
    for (let j = 0; j < subMenus.length; j++) {
      const subMenu = subMenus[j].link
      // If one of the submenus matches the current page, we return the list of submenus
      if (subMenu === path) {
        tabMenu = elementList[i]
        break
      }
    }
  }

  return tabMenu || { elementList: null }
}
