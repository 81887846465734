import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import slugify from 'slugify'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { MENU_TERTIARY_ID } from '../../../constants/components'
import { getTabMenu } from '../../../helpers/header'
import { mixins, theme } from '../../../styles'

export interface ITabNavigation {
  elementList: BlockTypes['menu-nav']['data']['elementList']
  activeMenuId?: string
}

function Item({
  link,
  title,
  mainTitle,
  color,
  isHighlight,
}: BlockTypes['menu-nav']['data']['elementList'][number] & {
  mainTitle: string
  isHighlight: boolean
}): JSX.Element {
  const { hit } = useContext(TagCommanderContext)

  function handleClick() {
    hit(
      {
        screen_clickableElementName: `header_${slugify(mainTitle, { lower: true })}_${slugify(
          title,
          { lower: true },
        )}`,
      },
      { isClickEvent: true },
    )
  }
  return (
    <>
      <li aria-current={isHighlight ? 'true' : undefined}>
        {isHighlight ? (
          <span onClick={handleClick}>{title}</span>
        ) : (
          <a onClick={handleClick} href={link}>
            {title}
          </a>
        )}
      </li>
      <style jsx>{`
        li:after {
          content: ${isHighlight ? '""' : 'none'};
          background-color: ${color || theme.cssVars.focusBlue};
        }
        a:before {
          content: '';
          cursor: pointer;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      `}</style>
    </>
  )
}

export function TabNavigation({ elementList, activeMenuId }: ITabNavigation): JSX.Element {
  const router = useRouter()
  const { elementList: tabMenuList, title: mainTitle } = useMemo(
    () => getTabMenu(elementList, router.asPath, activeMenuId),
    [elementList, router.asPath, activeMenuId],
  )

  if (!tabMenuList?.length) {
    return null
  }

  return (
    <>
      <nav
        role="navigation"
        aria-label="Menu Tertiaire"
        className="TabNavigation"
        id={MENU_TERTIARY_ID}
        tabIndex={-1}
      >
        <ul className="TabNavigation__List justify-center">
          {tabMenuList.map((element, index) => {
            const { link, title, color } = element
            return (
              <Item
                key={index}
                link={link}
                mainTitle={mainTitle}
                title={title}
                color={color}
                isHighlight={router.asPath === link}
              />
            )
          })}
        </ul>
      </nav>

      <style jsx>{`
        .TabNavigation {
          --border-bottom-size: 4px;
          position: relative;
          width: 100%;
          z-index: 50;
          border-bottom: var(--border-bottom-size) solid ${theme.cssVars.darkBlue};
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .TabNavigation {
            display: none;
          }
        }

        .TabNavigation__List {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 1fr;
          gap: 12px;
          list-style: none;
          margin: 0;
          padding: 0 20px;
        }

        .TabNavigation__List :global(li) {
          position: relative;
          color: ${theme.cssVars.white};
          padding: 10px;
          text-align: center;
          font-size: 13px;
          font-weight: 700;
          line-height: 154%;
        }

        .TabNavigation__List :global(li):after {
          position: absolute;
          display: block;
          width: 100%;
          height: var(--border-bottom-size);
          left: 0;
          bottom: calc(var(--border-bottom-size) * -1);
        }
      `}</style>
    </>
  )
}
